import Glide from '@glidejs/glide';

document.addEventListener('turbo:load', function () {

  const sliders = document.querySelectorAll('.glide');
  sliders.forEach(tool_glide => {
    new Glide(tool_glide, four_tab_glide_conf).mount();
  });
});


const four_tab_glide_conf = {
  type: 'slider',
  perView: 4,
  autoplay: 3000,
  breakpoints: {
    420: {
      perView:1,
      peek: {before:20,after:0}
    },
    768: {
      perView:1.5
    },
    992: {
      perView:2.5
    },
    1400: {
      perView:2.8
    }
  }
};

function initMap() {
  var map_div = document.getElementById('map')
  if (map_div) {
    latitude = parseFloat(map_div['dataset'].latitude)
    longitude = parseFloat(map_div['dataset'].longitude)
    const place = {
      lat: latitude,
      lng: longitude
    };
    const map = new google.maps.Map(document.getElementById("map"), {
      center: place,
      zoom: 14
    });
    marker = new google.maps.Marker ({
      position: place,
      map: map
    });
  }
}

window.initMap = initMap;

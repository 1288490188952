import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers';

var addChartForSkillPercentage = function(ctx) {
console.log(ctx, 'ctx');
  var options = {
    responsive: false,
    cutoutPercentage: 75,
    events: [] // disable hovering
    // hoverMode: 'index',
    // stacked: false,
    // elements: {
    //   line: {
    //     tension: 0
    //   }
    // }
  };

  // Configure datasets
  var percentage = ctx.dataset.percentage;
  var color = ctx.dataset.color;
  var data = {
      datasets: [{
          data: [percentage, (100-percentage)],
          backgroundColor: [color, "#F9F9F9"],
          borderWidth: [0, 0],
      }],
  };

  var myDoughnutChart = new Chart(ctx, {
      type: 'doughnut',
      data: data,
      options: options
  });

}


document.addEventListener("turbo:load", function() {
  var chartsToRender = document.getElementsByClassName('skill-chart')
  for (var i = 0, len = chartsToRender.length; i < len; i++) {
    addChartForSkillPercentage(chartsToRender[i])
  }
});

import { I18n } from "i18n-js";
import fr from "../../translations/fr.json";
import en from "../../translations/en.json";

$(document).on('turbo:load', function () {
  window.courseForm();
});


window.courseForm = function(){
  var showHideCourseVideoProviderType = function() {
    let course_presentation_provider_select = document.getElementById('course_presentation_provider');
    let youtube_video_input = document.getElementById('courses_youtube_video');
    let vimeo_video_input = document.getElementById('courses_vimeo_video');
    if (course_presentation_provider_select) {
      if (course_presentation_provider_select.value == 'youtube') {
        youtube_video_input.classList.remove('is-hidden');
        vimeo_video_input.classList.add('is-hidden');
      } else {
        vimeo_video_input.classList.remove('is-hidden');
        youtube_video_input.classList.add('is-hidden');
      }
    }
  };
  let course_presentation_provider_select = document.getElementById('course_presentation_provider');
  if (course_presentation_provider_select) {
    course_presentation_provider_select.addEventListener('change', showHideCourseVideoProviderType.bind(course_presentation_provider_select));
    showHideCourseVideoProviderType();
  }

  $('.new_basic_form_submit, .new_presentation_form_submit, .new_marketing_form_submit').on('click', function() {
    var tab = $(this).attr('id');
    if (tab === undefined) {
      tab = $('#courses-tabs ul li.is-active').next().attr('id');
    }

    if (tab !== $('#courses-tabs ul li.is-active').attr('id')) {
      window.formSubmitWithType('POST', tab);
    }
  });

  $('.basic_tab, .edit_basic_form_submit, .presentation_form_submit, .marketing_form_submit, .content_form_submit').on('click', function() {
    var tab = $(this).attr('id');
    if (tab === undefined) {
      tab = $('#courses-tabs ul li.is-active').next().attr('id');
    }

    if (tab !== $('#courses-tabs ul li.is-active').attr('id')) {
      window.formSubmitWithType('PUT', tab);
    }
  });

  if( $('#course_goals').length ) {
    $('#course_goals tr').first().append('<td><a data-association-insertion-node="tbody#course_goals" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="course_goal" data-associations="course_goals" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;> <td class=&quot;full-width&quot;> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;course[course_goals_attributes][new_course_goals][title]&quot; id=&quot;course_course_goals_attributes_new_course_goals_title&quot; /> </td>  <td class=&quot;remove&quot;> <input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;course[course_goals_attributes][new_course_goals][_destroy]&quot; id=&quot;course_course_goals_attributes_new_course_goals__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a> </td></tr>" href="#">+</a></td>');
    $('#course_goals .remove').first().remove();
  }
  if( $('#course_educational_objectives').length ) {
    $('#course_educational_objectives tr').first().append('<td><a data-association-insertion-node="tbody#course_educational_objectives" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="course_educational_objective" data-associations="course_educational_objectives" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;><td class=&quot;full-width&quot;><input class=&quot;input&quot; type=&quot;text&quot; name=&quot;course[course_educational_objectives_attributes][new_course_educational_objectives][title]&quot; id=&quot;course_course_educational_objectives_attributes_new_course_educational_objectives_title&quot; /> </td> <td class=&quot;remove&quot;><input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;course[course_educational_objectives_attributes][new_course_educational_objectives][_destroy]&quot; id=&quot;course_course_educational_objectives_attributes_new_course_educational_objectives__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a></td></tr>" href="#">+</a></td>');
    $('#course_educational_objectives .remove').first().remove();
  }
  if( $('#course_prerequisites').length ) {
    $('#course_prerequisites tr').first().append('<td><a data-association-insertion-node="tbody#course_prerequisites" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="course_prerequisite" data-associations="course_prerequisites" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;><td class=&quot;full-width&quot;> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;course[course_prerequisites_attributes][new_course_prerequisites][title]&quot; id=&quot;course_course_prerequisites_attributes_new_course_prerequisites_title&quot; /> </td> <td class=&quot;remove&quot;><input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;course[course_prerequisites_attributes][new_course_prerequisites][_destroy]&quot; id=&quot;course_course_prerequisites_attributes_new_course_prerequisites__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a></td></tr>" href="#">+</a></td>');
    $('#course_prerequisites .remove').first().remove();
  }

  if( $('#course_benefits').length ) {
    $('#course_benefits tr').first().append('<td><a data-association-insertion-node="tbody#course_benefits" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="course_benefit" data-associations="course_benefits" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;><td class=&quot;full-width&quot;>    <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;course[course_benefits_attributes][new_course_benefits][title]&quot; id=&quot;course_course_benefits_attributes_new_course_benefits_title&quot; />  </td> <td><input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;course[course_benefits_attributes][new_course_benefits][_destroy]&quot; id=&quot;course_course_benefits_attributes_new_course_benefits__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a></td></tr>" href="#">+</a></td>');
    $('#course_benefits .remove').first().remove();
  }

  if( $('#course_target_audiences').length ) {
    $('#course_target_audiences tr').first().append('<td><a data-association-insertion-node="tbody#course_target_audiences" data-association-insertion-method="append" class="button is-success is-small add_fields" data-association="course_target_audience" data-associations="course_target_audiences" data-association-insertion-template="<tr class=&quot;line-item nested-fields&quot;> <td class=&quot;full-width&quot;> <input class=&quot;input&quot; type=&quot;text&quot; name=&quot;course[course_target_audiences_attributes][new_course_target_audiences][title]&quot; id=&quot;course_course_target_audiences_attributes_new_course_target_audiences_title&quot; /> </td> <td><input value=&quot;false&quot; type=&quot;hidden&quot; name=&quot;course[course_target_audiences_attributes][new_course_target_audiences][_destroy]&quot; id=&quot;course_course_target_audiences_attributes_new_course_target_audiences__destroy&quot; /><a class=&quot;button is-danger is-small remove_fields dynamic&quot; href=&quot;#&quot;>-</a> </td></tr>" href="#">+</a></td>');
    $('#course_target_audiences .remove').first().remove();
  }

  $("tbody.js-sortable-rows").sortable({
    handle: ".js-sortable-row-handle",
    update: function (event, ui) {
      var data = $(this).sortable('serialize');
      // POST to server using $.post or $.ajax
      $.ajax({
        data: data,
        type: 'POST',
        url: $(this).data('reorder-url')
      });
    }
  });

  var profileSwitches = Array.prototype.slice.call(document.querySelectorAll(".isPublicCourseContentSwitch"), 0);

  if( profileSwitches.length > 0 ) {
    profileSwitches.forEach(function (profileSwitch) {
      var visibleBtn = profileSwitch.querySelector('.isProfileVisibilitySwitch-visible');
      var hiddenBtn = profileSwitch.querySelector('.isProfileVisibilitySwitch-hidden');

      var public_course = document.querySelector(".courseIsPublic");
      var private_course = document.querySelector(".courseIsPrivate");
      var course_featured_div = document.querySelector(".course-featured-div")

      hiddenBtn.addEventListener( "click", hideContent );
      visibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        public_course.classList.add('hidden')
        private_course.classList.remove('hidden')
        course_featured_div.classList.add('hidden')
      }

      function showContent () {
        public_course.classList.remove('hidden')
        private_course.classList.add('hidden')
        course_featured_div.classList.remove('hidden')
      }

    });
  }

  var automaticCertificateSwitches = Array.prototype.slice.call(document.querySelectorAll(".isAutomaticCerticateContentSwitch"), 0);

  if( automaticCertificateSwitches.length > 0 ) {
    automaticCertificateSwitches.forEach(function (automaticCertificateSwitch) {
      var visibleBtn = automaticCertificateSwitch.querySelector('.isAutomaticCerticateSwitch-true');
      var hiddenBtn = automaticCertificateSwitch.querySelector('.isAutomaticCerticateSwitch-false');

      var automaticCerticateTrue = document.querySelector(".automaticCerticateTrue");
      var automaticCerticateFalse = document.querySelector(".automaticCerticateFalse");

      hiddenBtn.addEventListener( "click", hideContent );
      visibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        automaticCerticateTrue.classList.add('hidden')
        automaticCerticateFalse.classList.remove('hidden')
        let updateAutomaticCertificateUrl = hiddenBtn.getAttribute('data-url');
        // update is_automatic_certicate in DB
        $.ajax({
          url: updateAutomaticCertificateUrl,
          type: "GET"
        })
      }

      function showContent () {
        automaticCerticateTrue.classList.remove('hidden')
        automaticCerticateFalse.classList.add('hidden')
        let updateAutomaticCertificateUrl = visibleBtn.getAttribute('data-url');
        // update is_automatic_certicate in DB
        $.ajax({
          url: updateAutomaticCertificateUrl,
          type: "GET"
        })
      }
    });
  }

  var internalCertificateSwitches = Array.prototype.slice.call(document.querySelectorAll(".isInternalCertificateContentSwitch"), 0);

  if( internalCertificateSwitches.length > 0 ) {
    internalCertificateSwitches.forEach(function (internalCertificateSwitch) {
      var visibleBtn = internalCertificateSwitch.querySelector('.isInternalCerticateSwitch-true');
      var hiddenBtn = internalCertificateSwitch.querySelector('.isInternalCerticateSwitch-false');

      var internalCertificationContent = document.querySelector("#internal_certification_content");

      hiddenBtn.addEventListener( "click", hideContent );
      visibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        internalCertificationContent.classList.add('hidden');
        let updateInternalCertificateUrl = hiddenBtn.getAttribute('data-url');
        // update is_automatic_certicate in DB
        $.ajax({
          url: updateInternalCertificateUrl,
          type: "GET"
        })
      }

      function showContent () {
        set_certificate_curve_positions();
        internalCertificationContent.classList.remove('hidden');
        let updateInternalCertificateUrl = visibleBtn.getAttribute('data-url');
        // update is_automatic_certicate in DB
        $.ajax({
          url: updateInternalCertificateUrl,
          type: "GET"
        })
      }
    });
  }

  var isAlwaysRunningCourseSwitches = Array.prototype.slice.call(document.querySelectorAll(".isAlwaysRunningCourseContentSwitch"), 0);

  if( isAlwaysRunningCourseSwitches.length > 0 ) {
    isAlwaysRunningCourseSwitches.forEach(function (alwaysRunningCourseSwitche) {
      var alwaysRunningVisibleBtn = alwaysRunningCourseSwitche.querySelector('.isAlwaysRunningVisibilitySwitch-visible');
      var periodic = alwaysRunningCourseSwitche.querySelector('.isAlwaysRunningVisibilitySwitch-hidden');

      var reminder_mail_div = document.querySelector(".reminder_mail_div");
      var registration_div = document.querySelector(".registration_div");
      var duration_div = document.querySelector(".duration_div");

      if(alwaysRunningVisibleBtn) {
        alwaysRunningVisibleBtn.addEventListener( "click", hideContent );
      }
      periodic.addEventListener( "click", showContent );

      function hideContent () {
        reminder_mail_div.classList.add('hidden')
        registration_div.classList.add('hidden')
        duration_div.classList.add('hidden')
      }

      function showContent () {
        reminder_mail_div.classList.remove('hidden')
        registration_div.classList.remove('hidden')
        duration_div.classList.remove('hidden')
      }

    });
  }

  var freeCourseSwitches = Array.prototype.slice.call(document.querySelectorAll(".isFreeCourseContentSwitch"), 0);

  if( freeCourseSwitches.length > 0 ) {
    freeCourseSwitches.forEach(function (freeCourseSwitch) {
      var priceVisibleBtn = freeCourseSwitch.querySelector('.isPriceVisibilitySwitch-visible');
      var priceHiddenBtn = freeCourseSwitch.querySelector('.isPriceVisibilitySwitch-hidden');

      var free_course = document.querySelector(".courseIsFree");
      var paid_course = document.querySelector(".courseIsPaid");
      var course_price = document.querySelector(".coursePrice");
      var course_special_price = document.querySelector(".courseSpecialPrice");
      var taxable_btn = document.querySelector(".course-taxable-btn");

      priceHiddenBtn.addEventListener( "click", hideContent );
      priceVisibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        free_course.classList.add('hidden')
        paid_course.classList.remove('hidden')
        course_price.classList.remove('hidden')
        course_special_price.classList.remove('hidden')
        taxable_btn.classList.remove('hidden')
      }

      function showContent () {
        free_course.classList.remove('hidden')
        paid_course.classList.add('hidden')
        course_price.classList.add('hidden')
        course_special_price.classList.add('hidden')
        taxable_btn.classList.add('hidden')
      }

    });
  }

  var featuredCourseContentSwitches = Array.prototype.slice.call(document.querySelectorAll(".isFeaturedCourseContentSwitch"), 0);

  if( featuredCourseContentSwitches.length > 0 ) {
    featuredCourseContentSwitches.forEach(function (featuredCourseContentSwitche) {
      var visibleBtn = featuredCourseContentSwitche.querySelector('.isFeaturedVisibilitySwitch-visible');
      var hiddenBtn = featuredCourseContentSwitche.querySelector('.isFeaturedVisibilitySwitch-hidden');
      var public_featured_course = document.querySelector(".courseFeaturedTrue");
      var private_course = document.querySelector(".courseFeaturedFalse");

      hiddenBtn.addEventListener( "click", hideContent );
      visibleBtn.addEventListener( "click", showContent );

      function hideContent () {
        public_featured_course.classList.add('hidden')
        private_course.classList.remove('hidden')
      }

      function showContent () {
        public_featured_course.classList.remove('hidden')
        private_course.classList.add('hidden')
      }
    });
  }

  const courseCertificateOptions = document.querySelectorAll('.course-certificate-option');
  courseCertificateOptions.forEach(option => {
    option.addEventListener('click', function() {
      // Remove 'selected' class from all options
      courseCertificateOptions.forEach(opt => opt.classList.remove('selected'));
      // Add 'selected' class to the clicked option
      option.classList.add('selected');
      // Find and check the corresponding hidden radio button
      document.getElementById(`course-certificate-${option.getAttribute('data-certificate-type')}`).checked = true;
      let updateCertificateUrl = option.getAttribute('data-url');
      // update certificate_type in DB
      $.ajax({
        url: updateCertificateUrl,
        type: "GET"
      })
    });
  });

  // curve html text
  let curveSvgContainer = document.getElementById('svgContainer');
  if (curveSvgContainer) {
    set_certificate_curve_positions();
  }

  function set_certificate_curve_positions() {
    document.fonts.ready.then(() => {
      const textElement = document.getElementById('curvedText');
      const pathElement = document.getElementById('curve');
      const textPath = textElement.querySelector('textPath');
      let retryCount = 0;
      const maxRetries = 10;

      const positionTextOnCurve = () => {
        // Get the length of the text and path
        const textLength = textElement.getComputedTextLength();
        const pathLength = pathElement.getTotalLength();

        // Calculate the startOffset to center the text
        const startOffset = (pathLength - textLength) / 2;

        // Set the startOffset to center the text on the path
        textPath.setAttribute('startOffset', `${startOffset}`);

        // Check if the calculation is off and retry after a short delay
        if (Math.abs(startOffset) < pathLength / 2 && retryCount < maxRetries) {
          retryCount++;
          setTimeout(positionTextOnCurve, 500); // Re-run after 800ms
        }
      };

      // Use requestAnimationFrame to ensure the DOM is fully ready
      requestAnimationFrame(positionTextOnCurve);
    });
  }

  var coursePeriodAttendedCheckBoxes = Array.prototype.slice.call(document.querySelectorAll(".course_period_attended"), 0);
  if( coursePeriodAttendedCheckBoxes.length > 0 ) {
    coursePeriodAttendedCheckBoxes.forEach(function (coursePeriodAttendedCheckBox) {
      // Add event listener for the 'change' event
      coursePeriodAttendedCheckBox.addEventListener("change", function() {
        // Check if the checkbox is checked
        if (this.checked) {
          var checkbox = this;
          const url = this.dataset.url;
          // Make AJAX call when checkbox is checked
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onreadystatechange = function() {
            if (xhr.responseText) {
              var xhr_response = JSON.parse(xhr.responseText);
            }
            if (xhr.readyState === 4 && xhr.status === 200 && xhr_response.status == 'success') {
              checkbox.disabled = true;
              console.log(xhr.responseText);
            } else if (xhr.readyState === 4 && xhr.status === 200) {
              checkbox.checked = false;
              alert(xhr_response.status);
            }
          };
          xhr.send(JSON.stringify({ checked: true }));
        } else {
          // Handle case when checkbox is unchecked
          // You can make another AJAX call or perform other actions here if needed
        }
      });

    });
  }

  var courseCompleteCheckBoxes = Array.prototype.slice.call(document.querySelectorAll(".course_complete"), 0);
  if( courseCompleteCheckBoxes.length > 0 ) {
    courseCompleteCheckBoxes.forEach(function (courseCompleteCheckBox) {
      // Add event listener for the 'change' event
      courseCompleteCheckBox.addEventListener("change", function() {
        // Check if the checkbox is checked
        if (this.checked) {
          var checkbox = this;
          const url = this.dataset.url;
          // Make AJAX call when checkbox is checked
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          xhr.onreadystatechange = function() {
            if (xhr.responseText) {
              var xhr_response = JSON.parse(xhr.responseText);
            }
            if (xhr.readyState === 4 && xhr.status === 200 && xhr_response.status == 'success') {
              checkbox.disabled = true;
              console.log(xhr.responseText);
            } else if (xhr.readyState === 4 && xhr.status === 200) {
              checkbox.checked = false;
              alert(xhr_response.status);
            }
          };
          xhr.send(JSON.stringify({ checked: true }));
        } else {
          // Handle case when checkbox is unchecked
          // You can make another AJAX call or perform other actions here if needed
        }
      });

    });
  }

  // limits the number of categories
  $('#course_durations_div').on('cocoon:after-insert', function() {
    increase_and_decrease_period_counter();
  });

  $('#course_durations_div').on('cocoon:after-remove', function() {
    increase_and_decrease_period_counter();
  });

  increase_and_decrease_period_counter();

  function increase_and_decrease_period_counter() {
    const i18n = new I18n({
      fr,
      en,
    });
    i18n.locale = $('body').data('locale');

    var periodCounterSpans = document.querySelectorAll("#course_durations_div .nested-fields .period_counter_span");

    // Filter out elements where nested_attributes form's style.display is not "none"
    const visibleElements = Array.from(periodCounterSpans).filter(element => {
      return element.parentElement.parentElement.parentElement.style.display !== "none";
    });

    visibleElements.forEach((element, index) => {
      if(index != 0) {
        element.innerHTML = i18n.t('period') + ' ' + (index+1);
      }
    });
  }
};

window.formSubmitWithType = function(type, tab_text){
  // Get form
  var form = $('.users_course.active')[0];
  $.updateAllTinyMCEAssociatedTextareas();
  // Create an FormData object
  var data = new FormData(form);

  $.ajax({
    url: $('.users_course.active').attr('action'),
    type: type,
    data: data,
    dataType: "script",
    processData: false,
    contentType: false,
    success: function(data) {
      $("#notification").html('');

      $('#courses-tabs li').removeClass('is-active');
      $('#' + tab_text).addClass('is-active');

      $('#tab-content div').removeClass('is-active');
      $('div[data-content="' + tab_text + '"]').addClass('is-active');
      $('.users_course').addClass('hidden');
      $('.users_course.' + tab_text + '').removeClass('hidden');

      if (tab_text != 'content' && tab_text != 'certifications') {
        $('.users_course').removeClass('active');
        $('.users_course.' + tab_text + '').addClass('active');
      }
      $(window).scrollTop(380);
    },
    error: function(data) {
      $(window).scrollTop(0);
    }
  });
};

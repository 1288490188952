document.addEventListener("turbo:load", function() {
  var notificationUserOrClient = document.querySelector('#notification_user_or_client');
  if(notificationUserOrClient) {
    notificationUserOrClient.addEventListener('change', function(event) {
      var notificationUsersSelect = document.querySelector('#notification_users_select');
      var notificationClientsSelect = document.querySelector('#notification_clients_select');
      if (this.value === 'client') {
        notificationClientsSelect.classList.remove('hidden');
        notificationUsersSelect.classList.add('hidden');
      } else {
        notificationUsersSelect.classList.remove('hidden');
        notificationClientsSelect.classList.add('hidden');
      }
    });
  }
});

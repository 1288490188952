// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"

// Rails UJS
import Rails from '@rails/ujs';
Rails.start();

import * as moment from 'moment';
window.moment = moment;

import grapesjs from 'grapesjs';
import 'grapesjs-blocks-basic';
import 'grapesjs-custom-code';

// Sub directory
import './src/plugins'
import '@popperjs/core'
import * as bootstrap from "bootstrap"
import '@nathanvda/cocoon';
import "./controllers"
import './src/application'

// Yarn add @doabit/semantic-ui-sass
// Some sass dependencies not compatible with our app, so add useful files.
import './src/semantic-ui-sass/src/semantic-ui';


// public layout js files
import './src/application/vendors/jquery-ui.min';
import './src/public_application';

document.addEventListener('turbo:load', function () {
  $('.menu-taggle').click(function() {
    $('nav').toggleClass('active')
  });
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
});

$(window).on("scroll", function() {
  if($(window).scrollTop() > 50) {
    $(".header-part").addClass("header-active");
  } else {
    //remove the background property so it comes transparent again (defined in your css)
   $(".header-part").removeClass("header-active");
  }
});

import ajaxCall from '../utilities/ajax.js';

function mark_as_read() {
  const mark_as_read_links = document.querySelectorAll('.mark-as-read-link');
  if(mark_as_read_links) {
    mark_as_read_links.forEach(link => {
      link.addEventListener('click', function(event) {
        event.preventDefault();
        link.parentElement.parentElement.classList.remove('active');
        $.ajax({
          url: link.href,
          type: "GET"
        })
      });
    });
  }
}

document.addEventListener("turbo:load", function() {
  mark_as_read();

  const notification_container = document.querySelector('#notificationHolder');
  if(notification_container) {
    $('#notificationHolder').on('mouseleave', function() {
      const new_notifications_tag = document.querySelectorAll('.new-notification');
      console.log('mouseleave');
      $.ajax({
        url: '/notifications/update_new_tag',
        method: 'GET',
        success: function(response) {
          if(response.status === 'ok') {
            new_notifications_tag.forEach(new_tag => {
              new_tag.classList.add('hidden');
            })
          }
          console.log('success', response.status)
        }
      });
    });
  }

  var futureEventsTab = document.querySelector('#calendar-future-events-tab');
  var pastEventsTab = document.querySelector('#calendar-past-events-tab');
  var futureEventsList = document.querySelector('#calendar-future-events');
  var pastEventsList = document.querySelector('#calendar-past-events');

  if(futureEventsTab && pastEventsTab) {
    futureEventsTab.addEventListener('click', function(event) {
      this.classList.remove('inactive-tab');
      this.classList.add('active-tab');
      pastEventsTab.classList.add('inactive-tab');
      pastEventsTab.classList.remove('active-tab');
      if (futureEventsList && pastEventsList) {
        futureEventsList.classList.remove('hidden');
        pastEventsList.classList.add('hidden');
      }
    });
  }

  if(futureEventsTab && pastEventsTab) {
    pastEventsTab.addEventListener('click', function(event) {
      this.classList.remove('inactive-tab');
      this.classList.add('active-tab');
      futureEventsTab.classList.add('inactive-tab');
      futureEventsTab.classList.remove('active-tab');
      if (futureEventsList && pastEventsList) {
        futureEventsList.classList.add('hidden');
        pastEventsList.classList.remove('hidden');
      }
    });
  }
});

// Event listener for before a Turbo Stream update is rendered
document.addEventListener('turbo:before-stream-render', function(event) {
  mark_as_read();
});


// Event listener for after a Turbo Stream update is rendered
document.addEventListener('turbo:frame-render', function(event) {
  mark_as_read();
});
